import {track, useEditor} from "@tldraw/tldraw";
import {GeoShapeGeoStyle} from "@tldraw/editor";

export default function ShapeTool({tool, onSelect}) {

    return (
        <ShapeToolComponent
            tool={tool}
            onSelect={onSelect}
        ></ShapeToolComponent>
    )
}

const ShapeToolComponent = track(({ tool, onSelect }) => {

    const editor = useEditor();

    const shape = editor.getStyleForNextShape(GeoShapeGeoStyle);

    const componentId = 'geo';

    const isToolSelected = tool === componentId;

    const handleClick = () => {
        if (onSelect) {
            onSelect(componentId);
        }
    };

    return (
        <button
            title={`Shapes`}
            className={`default-tool-classes
                        ${isToolSelected ? 'active-tool-classes' : 'not-active-tool-classes'}`}
            onClick={handleClick}
        >

            <img
                className="h-6 w-6 m-3"
                src={`https://unpkg.com/@tldraw/assets@2.1.4/icons/icon/geo-${shape}.svg`}
            ></img>
        </button>
    )
});
