import {
    DefaultStylePanel,
    DefaultStylePanelContent,
    TLUiStylePanelProps,
    useRelevantStyles,
    DefaultContextMenu, TLUiContextMenuProps, TldrawUiMenuGroup, TldrawUiMenuItem, DefaultContextMenuContent
} from "@tldraw/tldraw";
import {TLComponents} from "tldraw";

function CustomStylePanel(props: TLUiStylePanelProps) {

    const styles = useRelevantStyles()

    return (
        <div className={`!mt-12 !mr-1 !border !border-gray-200 !rounded !ml-0`}>
            <DefaultStylePanel {...props}>
                <DefaultStylePanelContent styles={styles} />
            </DefaultStylePanel>
        </div>

    )
}

// @ts-ignore
export const PixelPaperUIOverrides: TLComponents = {
    Toolbar: null,
    ZoomMenu: null,
    MenuPanel: null,
    Minimap: null,
    DebugMenu: null,
    PageMenu: null,
    StylePanel: CustomStylePanel,
}
