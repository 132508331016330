import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import '@tldraw/tldraw/tldraw.css'
import './custom-ui.css'
import {BrowserRouter} from "react-router-dom";

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://992cbd762612d301197b90892aae3e6f@o954308.ingest.us.sentry.io/4507259394260992",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "*", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
)
