import React, { createContext, useContext } from 'react';
import {BusinessCard} from "./Shapes/BusinessCard";

const ModalContext = createContext({
    isOpen: false,  // Default values
    editingCard: {
        props: {
            text: '',
        }
    },
    openModal: (shape) => console.warn("openModal not provided"),
    closeModal: () => console.warn("closeModal not provided"),
});

export function ModalProvider({ children }) {
    const [isOpen, setIsOpen] = React.useState(false);

    const [editingCard, setEditingCard] = React.useState({
        props: {
            text: '',
        }
    });

    const openModal = (shape: BusinessCard) => {
        console.log(shape);
        setEditingCard(shape);
        setIsOpen(true);
    }

    const closeModal = () => setIsOpen(false);

    return (
        <ModalContext.Provider value={{ isOpen, openModal, closeModal, editingCard }}>
            {children}
        </ModalContext.Provider>
    );
}

export function useModal() {
    return useContext(ModalContext);
}
