export default function UploadTool({ onSelect }) {

    const componentId = 'geo';

    const handleClick = () => {
        if (onSelect) {
            onSelect(componentId);
        }
    };

    return (
        <button
            title={`Upload PDF or Image (⌘ U)`}
            className={`hidden sm:flex default-tool-classes not-active-tool-classes`}
            onClick={handleClick}
        >

            <svg className={`h-7 w-7 m-3`} width="24px" height="24px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M6 20L18 20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12 16V4M12 4L15.5 7.5M12 4L8.5 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </button>
    )
}
