import {Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {TLEventMapHandler, useEditor} from "@tldraw/tldraw";
import {usePeers} from "../Utils/Utils";


export function CurrentUsers() {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const editor = useEditor();

    function followUser(id)
    {
        if(editor.getInstanceState().followingUserId !== null){
            editor.stopFollowingUser();
        }
        else {
            let userId = id.split(':');
            editor.startFollowingUser(userId[1]);
        }
    }

    let peers = usePeers();

    return (

            <Menu as="div" className="relative inline-block text-left h-full flex-grow-0 flex-shrink-0">

                <Menu.Button
                    className="duration-150 h-full duration-150 px-3 flex items-center space-x-1 all-events text-gray-700 dark:text-white hover:opacity-80"
                >
                    <div className={`flex flex-row`}>
                        { peers.map(peer => (
                            <div key={peer.id} style={{backgroundColor: peer.color}} className={`h-8 w-8 rounded-full bg-blue-700 text-white flex items-center text-white justify-center ring-2 ring-white dark:ring-gray-100 text-xs`}>
                                {peer.userName.charAt(0)}
                            </div>
                        ))}
                    </div>
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-y-95"
                    enterTo="transform opacity-100 scale-y-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-y-100"
                    leaveTo="transform opacity-0 scale-y-95"
                >
                    <Menu.Items className="absolute
                    all-events -left-40 origin-top-left z-10 mt-2 w-56 rounded-md bg-white dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            { peers.map(peer => (
                                <Menu.Item key={peer.id}>
                                    {({ active }) => (
                                        <button onClick={() => {followUser(peer.id)}}
                                            className={classNames(
                                                active ? 'bg-gray-100 hover:bg-gray-50 dark:hover:bg-gray-600 text-gray-900 dark:text-white' : 'text-gray-700 dark:text-gray-300',
                                                'flex w-full px-4 py-2 text-sm flex justify-between'
                                            )}
                                        >
                                            <span>{ peer.userName}</span>
                                            <span className="text-gray-400">
                                                {peer.id.split(':')[1] === editor.getInstanceState().followingUserId ? 'Stop Following' : 'Follow'}
                                            </span>
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}

                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
    )
}
