import { Editor, VecModel} from "@tldraw/tldraw";
import { uploadFileToS3 } from './Utils.ts';
import * as pdfjsLib from "pdfjs-dist";
import {compact, createShapeId, TLAsset, TLShapePartial, Vec, VecLike} from "@tldraw/editor";

function centerSelectionAroundPoint(editor: Editor, position: VecLike) {
    // Re-position shapes so that the center of the group is at the provided point
    const viewportPageBounds = editor.getViewportPageBounds()
    let selectionPageBounds = editor.getSelectionPageBounds()

    if (selectionPageBounds) {
        const offset = selectionPageBounds!.center.sub(position)

        editor.updateShapes(
            editor.getSelectedShapes().map((shape) => {
                const localRotation = editor.getShapeParentTransform(shape).decompose().rotation
                const localDelta = Vec.Rot(offset, -localRotation)
                return {
                    id: shape.id,
                    type: shape.type,
                    x: shape.x! - localDelta.x,
                    y: shape.y! - localDelta.y,
                }
            })
        )
    }

    // Zoom out to fit the shapes, if necessary
    selectionPageBounds = editor.getSelectionPageBounds()
    if (selectionPageBounds && !viewportPageBounds.contains(selectionPageBounds)) {
        editor.zoomToSelection()
    }
}

function createShapesFromAssets(editor: Editor,
                                assets: TLAsset[],
                                position: VecLike)
{
    const currentPoint = Vec.From(position);
    const partials: TLShapePartial[] = [];

    for (const asset of assets) {
        switch (asset.type) {
            case 'bookmark':
                partials.push({
                    id: createShapeId(),
                    type: 'bookmark',
                    x: currentPoint.x - 150, // Keeps horizontal centering for bookmark
                    y: currentPoint.y - 160, // Starts position for bookmark
                    opacity: 1,
                    props: {
                        assetId: asset.id,
                        url: asset.props.src,
                    },
                });

                // Increment y position by a fixed amount (bookmark height plus some margin)
                currentPoint.y += 300; // Adjust this value to fit the actual height of your bookmark plus desired spacing
                break;

            case 'image':
                partials.push({
                    id: createShapeId(),
                    type: 'image',
                    x: currentPoint.x - asset.props.w / 2, // Keeps horizontal centering for images
                    y: currentPoint.y - asset.props.h / 2, // Starts position for image
                    opacity: 1,
                    props: {
                        assetId: asset.id,
                        w: asset.props.w,
                        h: asset.props.h,
                    },
                });

                // Increment y position by the height of the image plus some margin
                currentPoint.y += (asset.props.h + 40);
                break;

            case 'video':
                partials.push({
                    id: createShapeId(),
                    type: 'video',
                    x: currentPoint.x - asset.props.w / 2, // Keeps horizontal centering for videos
                    y: currentPoint.y - asset.props.h / 2, // Starts position for video
                    opacity: 1,
                    props: {
                        assetId: asset.id,
                        w: asset.props.w,
                        h: asset.props.h,
                    },
                });

                // Increment y position by the height of the video plus some margin
                currentPoint.y += (asset.props.h + 40);
                break;
        }
    }

    editor.batch(() => {
        // Create any assets
        const assetsToCreate = assets.filter((asset) => !editor.getAsset(asset.id))
        if (assetsToCreate.length) {
            editor.createAssets(assetsToCreate)
        }

        // Create the shapes
        // @ts-ignore
        editor.createShapes(partials).select(...partials.map((p) => p.id))

        // Re-position shapes so that the center of the group is at the provided point
        centerSelectionAroundPoint(editor, position)
    })

    return partials.map((p) => p.id)
}

function dataURLtoBlob(dataurl) {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export async function uploadFilesToPixelPaper(point: Vec | VecModel | undefined, editor: Editor, files) {
    const position =
        point ??
        (editor.inputs.shiftKey ? editor.inputs.currentPagePoint : editor.getViewportPageCenter())

    const pagePoint = new Vec(position.x, position.y)

    const assets: TLAsset[] = []

    const maxAssetSize = 999999999;
    const acceptedImageMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
    const acceptedVideoMimeTypes = ['video/mp4', 'video/webm', 'video/quicktime'];

    // Get the asset based on the external content generated...
    await Promise.all(
        files.map(async (file, i) => {
            if (file.size > maxAssetSize) {
                console.warn(
                    `File size too big: ${(file.size / 1024).toFixed()}kb > ${(
                        maxAssetSize / 1024
                    ).toFixed()}kb`
                )
                return null
            }

            // Use mime type instead of file ext, this is because
            // window.navigator.clipboard does not preserve file names
            // of copied files.
            if (!file.type) {
                throw new Error('No mime type')
            }

            // We can only accept certain extensions (either images or a videos)
            if (!acceptedImageMimeTypes.concat(acceptedVideoMimeTypes).includes(file.type)) {
                console.warn(`${file.name} not loaded - Extension not allowed.`)
                return null
            }

            try {
                const asset = await editor.getAssetForExternalContent({type: 'file', file});

                if (!asset) {
                    throw Error('Could not create an asset')
                }

                assets[i] = asset

            } catch (error) {

                alert(error);

                return null
            }
        })
    )

    // Loop through the assets, uploading the value of each to our S3 bucket
    // and then replacing the src value with URL from data:base64.
    for (const asset of assets) {

        if (asset.props.src === null) {
            return;
        }

        let fileExtension = asset.props.mimeType.split('/');

        const blob = dataURLtoBlob(asset.props.src); // Convert data URL to blob

        const file = new File([blob], `${asset.id}.${fileExtension[1]}`, {type: asset.props.mimeType});

        let uploadedUrl = await uploadFileToS3(file);

        // Create a shallow copy of `asset` and also `asset.props`
        let updatedAsset = {
            ...asset,
            props: {
                ...asset.props,
                src: uploadedUrl  // Update the `src` in the new copy
            }
        };

        // @ts-ignore
        editor.updateAssets([updatedAsset]);
    }

    createShapesFromAssets(editor, compact(assets), pagePoint)
}

export const uploadImagesToCanvas = async function (editor: Editor, files: FileList){

    // Convert the FileList to an array...
    const fileArray = Array.from(files);

    await uploadFilesToPixelPaper(undefined, editor, fileArray);
}

export function uploadPdfToCanvas(editor: Editor, file: File)
{
    // @ts-ignore
    pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';

    let reader = new FileReader();

    reader.onload = function(evt) {
        let arrayBuffer = evt.target.result;

        let loadingTask = pdfjsLib.getDocument(arrayBuffer);

        // All we need to do now is convert the PDF into an array of files, then create the new page (and move to it)
        // And then upload the full PDF.
        editor.createPage({name: file.name});

        // Set it to the new last page.
        let editorPages = editor.getPages();

        editor.setCurrentPage(editorPages[editorPages.length - 1]);

        // I've refactored it up to here. This loadingTask now needs to render each page
        // and add it as a file to the files array. We can then pass that to our function that uploads an array of files to the canvas.
        loadingTask.promise.then(function(pdf) {

            const promises = [];

            for (let i = 1; i <= pdf.numPages; i++) {
                promises.push(
                    pdf.getPage(i).then((page) => uploadPageToPixelPaper(page, 1.5, i))
                );
            }

            // Listen for all promises to resolve...
            Promise.all(promises).then(async (files) => {

                console.log('All promises resolved...');

                await uploadFilesToPixelPaper(undefined, editor, files);

                // Find the first shape that was uploaded...
                let shapes = editor.getCurrentPageShapes();

                editor.panZoomIntoView([shapes[0].id]);

            }).catch((error) => {
                console.error("An error occurred:", error);
            });


        }, function (reason) {
            console.error(reason);
        });
    };

    // Read the file as an ArrayBuffer
    reader.readAsArrayBuffer(file);
}

function uploadPageToPixelPaper(page, scale, pageNumber) {
    return new Promise((resolve, reject) => {
        const viewport = page.getViewport({ scale: scale });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
            canvasContext: context,
            viewport: viewport
        };

        page.render(renderContext).promise.then(() => {
            canvas.toBlob(function(blob) {

                // Convert the blob to a File object
                const file = new File([blob], `page-${pageNumber}.png`, { type: 'image/png' });

                resolve(file);

            }, 'image/png');

        }).catch(reject);
    });
}
