import {AssetRecordType, Editor, useComputed, useEditor, useValue} from "@tldraw/tldraw";
import axios from "axios";
import {useEffect, useMemo, useState} from "react";

/**
 * Upload an image (passed as base64 data) to S3 through HQ.
 * @param file: File
 */
export function uploadFileToS3(file) {
    return new Promise(async (resolve, reject) => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');

        // Create an instance of FormData
        const formData = new FormData();
        formData.append('file', file); // Append the file to the FormData object
        formData.append('accessToken', token); // Append additional data if needed

        try {
            // Update the axios post request to use FormData
            let response = await axios.post(import.meta.env.VITE_API_URL + '/api/paper/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Ensure headers are set for multipart/form-data
                },
            });
            resolve(response.data.url);
        } catch (error) {
            reject(error);
        }
    })
}

export function usePeers() {
    const editor = useEditor();

    // Assuming presence records include necessary user information or there's a method to get users by ID
    const $presences = useMemo(() => {
        // @ts-ignore
        return editor.store.query.records('instance_presence', () => ({
            userId: { neq: editor.user.getId() },
        }))
    }, [editor]);

    // Updated to fetch user objects. This assumes that the presences include all necessary user data,
    // or you have a method to retrieve user objects by ID (e.g., editor.getUserById).
    const $userObjects = useComputed(
        'userObjects',
        () => {
            // Fetch user objects based on presence records. This is a placeholder and may need to be adjusted
            // based on how your application's data structure and methods are set up.
            return $presences.get().filter(user => user !== null); // Filter out any nulls in case a user isn't found
        },
        { isEqual: (a, b) => JSON.stringify(a) === JSON.stringify(b) }, // Updated equality check for objects
        [$presences]
    );


    return useValue($userObjects);
}

export function handleDarkModeToggle() {
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const toggleDarkMode = (event) => {
            // Toggle dark mode on CMD + '/' or CTRL + '/' press
            if (event.key === '/' && (event.metaKey || event.ctrlKey)) {
                setDarkMode(prevDarkMode => !prevDarkMode);
            }
        };

        window.addEventListener('keydown', toggleDarkMode);
        return () => window.removeEventListener('keydown', toggleDarkMode);
    }, []);

    // Apply the 'dark' class to the body when darkMode is true
    useEffect(() => {
        if (darkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, [darkMode]);
}
