import React from 'react';
import ZoomDropdown from "./ZoomDropdown";
import {track, useEditor} from "@tldraw/tldraw";

const ZoomMenu = track(() => {
    const editor = useEditor();

    const zoomIn = () => {
        editor.zoomIn(editor.getViewportScreenCenter(), { duration: 120 });
    }

    const zoomOut = () => {
        editor.zoomOut(editor.getViewportScreenCenter(), { duration: 120 });
    }

    return (
        <span className="inline-flex rounded-md shadow-sm p-1">

              <button
                  onClick={ zoomOut }
                  type="button"
                  className="relative all-events inline-flex items-center rounded-l-md
                  bg-white dark:bg-gray-800
                  text-gray-400 dark:text-white
                  hover:bg-gray-50 dark:hover:bg-gray-600 duration-150
                  px-2 py-2 ring-1 ring-inset ring-gray-300
                  z-50
                  focus:z-50"
              >
                <span className="sr-only">Zoom out</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                       stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15"/>
                  </svg>
              </button>


              <ZoomDropdown/>

              <button
                  onClick={ zoomIn }
                  type="button"
                  className="relative all-events inline-flex items-center rounded-r-md
                  bg-white dark:bg-gray-800
                  text-gray-400 dark:text-white
                  hover:bg-gray-50 dark:hover:bg-gray-600 duration-150
                  px-2 py-2 ring-1 ring-inset ring-gray-300 focus:z-10"
              >
                <span className="sr-only">Zoom In</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                       stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
                  </svg>
              </button>
            </span>
    );
})

export default ZoomMenu;
