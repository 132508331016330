import {CursorArrowRaysIcon} from "@heroicons/react/24/outline";
import HandIcon from "./IconSvgs/HandIcon";

export default function PanTool({ tool, onSelect }) {

    const componentId = 'hand';

    const isToolSelected = tool === componentId;

    const handleClick = () => {
        if (onSelect) {
            onSelect(componentId);
        }
    };

    return (
        <button
            title={`Pan (H)`}
            className={`hidden sm:flex default-tool-classes
                        ${isToolSelected ? 'active-tool-classes' : 'not-active-tool-classes'}`}
            onClick={handleClick}
        >
            <HandIcon
                className={`w-7 h-7 m-3`}
            />
        </button>
    )
}
