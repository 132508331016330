import { track, useEditor } from "@tldraw/tldraw";
import {useEffect, useRef } from "react";
import {uploadImagesToCanvas, uploadPdfToCanvas} from "./Utils/FileUploads";
import ZoomMenu from "./Components/ZoomMenu";
import PagesMenu from './Components/Pages/PagesMenu';
import SelectTool from "./Components/Toolbar/Icons/SelectTool";
import PanTool from "./Components/Toolbar/Icons/PanTool";
import DrawTool from "./Components/Toolbar/Icons/DrawTool";
import EraserTool from "./Components/Toolbar/Icons/EraserTool";
import NoteTool from "./Components/Toolbar/Icons/NoteTool";
import ArrowTool from "./Components/Toolbar/Icons/ArrowTool";
import ShapeTool from "./Components/Toolbar/Icons/ShapeTool";
import UploadTool from "./Components/Toolbar/Icons/UploadTool";
import ImageTool from "./Components/Toolbar/Icons/ImageTool";
import {CurrentUsers} from "./Components/CurrentUsers";
import {handleDarkModeToggle} from "./Utils/Utils";
import PaperTitle from "./Components/PaperTitle";
import TextTool from "./Components/Toolbar/Icons/TextTool";

let PixelPaperUI;

export default PixelPaperUI = track(() => {

    const editor = useEditor();

    window.editor = editor;

    useEffect(() => {
        editor.updateInstanceState({isDebugMode: false})
    }, []);

    handleDarkModeToggle();

    const handleToolSelect = (toolId) => {

        editor.setCurrentTool(toolId);

    };

    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);

    /**
     * We can upload:
     * 1. Any Image. This just uploads it to the current page.
     * 2. Any PDF document
     *      This will show a modal that asks them if they'd like to upload all pages to a single PixelPaper page (and lock the zoom / scroll)
     *      or each page to a new PixelPaper page.
     */
    const uploadDocument = () => {
        fileInputRef.current.click();
    }

    const handleFileChange = async (event) => {

        const file = event.target.files[0];

        if (file) {

            if(file.type.startsWith('image/')) {
                await uploadImagesToCanvas(editor, event.target.files);
            }
            else if(file.type === 'application/pdf') {
                uploadPdfToCanvas(editor, file);
            }

        }
    };

    return (
        <div className="custom-layout flex-grow-0 flex-shrink-0">

            <div className="flex flex-col h-screen">

                <div className="flex justify-between px-4 border-b
                                border-gray-200 dark:border-gray-500
                                bg-white dark:bg-gray-800">

                    <div className={`w-1/3 flex space-x-2`}>
                        <PagesMenu/>
                    </div>


                    <div className={`w-1/3 flex justify-center`}>
                        <PaperTitle />
                    </div>

                    <div className={`flex flex-row space-x-1 w-1/3 hidden sm:flex justify-end`}>

                        <CurrentUsers/>

                        <ZoomMenu
                            editor={editor}
                        />
                    </div>

                </div>

                <div className="flex-grow"></div>

                <div className="flex items-center justify-center">

                    <div className="max-w-2xl w-fit all-events bg-white dark:bg-gray-800
                    border border-gray-200 dark:border-gray-700 rounded flex">

                        <SelectTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        {
                            editor.getInstanceState().followingUserId === null &&
                            <PanTool
                                tool={editor.getCurrentTool().id}
                                onSelect={handleToolSelect}
                            />
                        }

                        <DrawTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        <EraserTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        <TextTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        ></TextTool>

                        <NoteTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        <ArrowTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        <ShapeTool
                            tool={editor.getCurrentTool().id}
                            onSelect={handleToolSelect}
                        />

                        <UploadTool onSelect={uploadDocument}/>

                    </div>
                </div>
            </div>


            <input
                type="file"
                multiple
                ref={fileInputRef}
                className={`hidden`}
                accept="application/pdf,image/png, image/jpeg, image/webp"
                onChange={handleFileChange}
            />

            <canvas id={`the-canvas`}/>
        </div>
    )
});

