import {PencilSquareIcon} from "@heroicons/react/24/outline";

export default function NoteTool({ tool, onSelect }) {

    const componentId = 'note';

    const isToolSelected = tool === componentId;

    const handleClick = () => {
        if (onSelect) {
            onSelect(componentId);
        }
    };

    return (
        <button
            title={`Sticky Notes (N)`}
            className={`hidden sm:flex default-tool-classes
                        ${isToolSelected ? 'active-tool-classes' : 'not-active-tool-classes'}`}
            onClick={handleClick}
        >
            <svg className={`w-6 h-6 my-3 mx-4`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                 >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M6 4h12a2 2 0 0 1 2 2v7h-5a2 2 0 0 0 -2 2v5h-7a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2z"/>
                <path d="M20 13v.172a2 2 0 0 1 -.586 1.414l-4.828 4.828a2 2 0 0 1 -1.414 .586h-.172"/>
            </svg>
        </button>
    )
}
