import '@tldraw/tldraw/tldraw.css'
import './custom-ui.css'
import {Route, Routes} from "react-router-dom";
import PixelPaper from "./PixelPaper";
import {ModalProvider} from "./ModalContext";
import {RoomProvider} from "./RoomContext";


function AppRoutes() {
    return (
        <RoomProvider>
            <ModalProvider>
                <Routes>
                    <Route path="/room/:roomId" element={<PixelPaper />} />
                    <Route path="" element={<PixelPaper />} />
                </Routes>
            </ModalProvider>
        </RoomProvider>
    );
}

export default function App() {

    return (
        <AppRoutes />
    )
}
