import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import {track, useEditor} from "@tldraw/tldraw";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ZoomDropdown = track(() => {
    const editor = useEditor();

    const zoomTo100 = () => {
        editor.resetZoom(editor.getViewportScreenCenter(), { duration: 200 });
    }

    const zoomToFit = () => {
        editor.zoomToFit({ duration: 200 });
    }

    const zoomIn = () => {
        editor.zoomIn(editor.getViewportScreenCenter(), { duration: 120 });
    }

    const zoomOut = () => {
        editor.zoomOut(editor.getViewportScreenCenter(), { duration: 120 });
    }

    const addGrid = () => {
        editor.updateInstanceState({ isGridMode: !editor.getInstanceState().isGridMode })
    }

    return (
        <Menu as="div" className="relative inline-block text-left h-full">
            <Menu.Button
                className="
                hover:bg-gray-50 dark:hover:bg-gray-700 duration-150 h-full duration-150 px-3 flex items-center space-x-1 all-events text-gray-700
                dark:text-white border-y border-gray-300"
            >
                { Math.round(editor.getZoomLevel() * 100) }%
                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 dark:text-white" aria-hidden="true"
                />
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute all-events right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white
                dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button onClick={ zoomTo100 }
                                        className={classNames(
                                            active ? 'hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-white' : 'text-gray-700 dark:text-gray-300',
                                            'flex w-full px-4 py-2 text-sm flex justify-between'
                                        )}
                                >
                                    <span>Reset to 100%</span>
                                    <span className="text-gray-400">⇧ + 0</span>
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button onClick={ zoomToFit }
                                    className={classNames(
                                        active ? 'hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-white' : 'text-gray-700 dark:text-gray-300',
                                        'flex w-full px-4 py-2 text-sm flex justify-between'
                                    )}
                                >
                                    <span>Zoom to fit</span>
                                    <span className="text-gray-400">⇧ + 1</span>
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button onClick={ zoomIn }
                                   className={classNames(
                                       active ? 'hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-white' : 'text-gray-700 dark:text-gray-300',
                                       'flex w-full px-4 py-2 text-sm flex justify-between'
                                   )}
                                >
                                    <span>Zoom in</span>
                                    <span className="text-gray-400">+</span>
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button onClick={ zoomOut }
                                   className={classNames(
                                       active ? 'hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-white' : 'text-gray-700 dark:text-gray-300',
                                       'flex w-full px-4 py-2 text-sm flex justify-between'
                                   )}
                                >
                                    <span>Zoom out</span>
                                    <span className="text-gray-400">-</span>
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button onClick={ addGrid }
                                   className={classNames(
                                       active ? 'hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-white' : 'text-gray-700 dark:text-gray-300',
                                       'flex w-full px-4 py-2 text-sm flex justify-between'
                                   )}
                                >
                                    <span>Toggle grid</span>
                                    <span className="text-gray-400">⌘ + '</span>
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
});

export default ZoomDropdown;
