import {CursorArrowRaysIcon, PencilIcon} from "@heroicons/react/24/outline";
import PenDrawingIcon from "./IconSvgs/PenDrawingIcon";


export default function DrawTool({ tool, onSelect }) {

    const componentId = 'draw';

    const isToolSelected = tool === componentId;

    const handleClick = () => {
        if (onSelect) {
            onSelect(componentId);
        }
    };

    return (
        <button
            title={`Draw (D)`}
            className={`default-tool-classes
                        ${isToolSelected ? 'active-tool-classes' : 'not-active-tool-classes'}`}
            onClick={handleClick}
        >
            <svg width="24px" height="24px" className={`w-6 h-6 my-3 mx-4`} viewBox="0 0 24 24" strokeWidth="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor">
                <path d="M14.3632 5.65156L15.8431 4.17157C16.6242 3.39052 17.8905 3.39052 18.6716 4.17157L20.0858 5.58579C20.8668 6.36683 20.8668 7.63316 20.0858 8.41421L18.6058 9.8942M14.3632 5.65156L4.74749 15.2672C4.41542 15.5993 4.21079 16.0376 4.16947 16.5054L3.92738 19.2459C3.87261 19.8659 4.39148 20.3848 5.0115 20.33L7.75191 20.0879C8.21972 20.0466 8.65806 19.8419 8.99013 19.5099L18.6058 9.8942M14.3632 5.65156L18.6058 9.8942" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </button>
    )
}
