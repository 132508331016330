import React from 'react';
import {ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid/index.js";
import {Menu, Transition} from "@headlessui/react";
import {Fragment, useState} from 'react'
import {track, useEditor} from "@tldraw/tldraw";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PagesMenu() {
    return (
        <PagesMenuComponent />
    )
}

const PagesMenuComponent = track(() => {
    const editor = useEditor();

    const changePage = (page) => {
        editor.setCurrentPage(page);
    }

    const addPage = () => {
        // Find the next page number...
        let nextPage = editor.getPages().length + 1;
        editor.createPage({ name: `Page ${nextPage}`});
    }

    const incrementPage = (amount)  => {
        // Find the current page...
        let i = 0;
        let r = null;

        editor.getPages().forEach((page) => {
            if(page.id === editor.getCurrentPage().id) {
                r = i;
            }
            i++
        });

        // r now equals the index that the user is through.
        let desiredNewPage = editor.getPages()[r + amount];

        try {
            editor.setCurrentPage(desiredNewPage);
        }
        catch (e){

            if(amount > 0){
                let overflowAmount = r + amount;

                // This means we've tried to move to a new page that doesn't exist.
                editor.createPage({ name: `Page ${overflowAmount}`});

                let pages = editor.getPages();

                let newPage = editor.getPages()[pages.length - 1];

                editor.setCurrentPage(newPage);
            }
        }
    }

    return (
        <span className="isolate inline-flex rounded-md shadow-sm p-1">
              <button
                  onClick={() => { incrementPage(-1) }}
                  type="button"
                  className="relative all-events inline-flex items-center
                  rounded-l-md
                  px-2 py-2 ring-1 ring-inset ring-gray-300
                  bg-white dark:bg-gray-800 text-gray-400 dark:text-white
                  hover:bg-gray-50
                  dark:hover:bg-gray-700
                  duration-150
                   focus:z-10"
              >
                <span className="sr-only">Previous Page</span>
                  <ChevronLeftIcon className="h-5 w-5" />
              </button>

              <Menu as="div" className="relative inline-block text-left h-full">

                <div className="h-full">
                    <Menu.Button
                        className="h-full duration-150 px-3 flex items-center space-x-1 all-events border-y
                        text-gray-700 hover:bg-gray-50 border-gray-300
                        dark:text-white dark:hover:bg-gray-700
                        ">
                        { editor.getCurrentPage().name }
                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 dark:text-gray-100" aria-hidden="true" />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items static className="absolute all-events left-0 z-10 mt-2 w-56 origin-top-left
                    rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
                    bg-white dark:bg-gray-700
                    ">
                        <div className="py-1">
                            <div className={`flex justify-between items-center px-4 py-2 text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-600 pb-3`}>
                                <p className={`text-xs`}>Pages</p>

                                <button onClick={addPage} className={`rounded-full hover:bg-gray-100 dark:hover:bg-gray-800`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
                                    </svg>
                                </button>
                            </div>
                            {editor.getPages().map((page, index) => (
                                <Menu.Item key={index}>
                                    {({ active }) => (
                                        <button
                                            onClick={() => { changePage(page)}}
                                            className={classNames(
                                                active ? 'bg-gray-100 hover:bg-gray-100 duration-150 hover:bg-gray-50 dark:hover:bg-gray-600 text-gray-900 dark:text-white' : 'text-gray-700 dark:text-gray-300',
                                                'block px-4 py-3 text-xs w-full text-left'
                                            )}
                                        >
                                            <span>{page.name}</span>
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
              </Menu>

              <button
                  onClick={() => { incrementPage(1) }}
                  type="button"
                  className="relative all-events inline-flex items-center
                  rounded-r-md
                  px-2 py-2 ring-1 ring-inset ring-gray-300
                  bg-white dark:bg-gray-800 text-gray-400 dark:text-white
                  hover:bg-gray-50
                  dark:hover:bg-gray-700
                  duration-150
                   focus:z-10"
              >
                <span className="sr-only">Next Page</span>
                  <ChevronRightIcon className="h-5 w-5" />
              </button>
        </span>
    );
});
