import {Editor, getEmbedInfo, Tldraw, TLEventMapHandler, useEditor} from "@tldraw/tldraw";
import {PixelPaperUIOverrides} from "./Components/PixelPaperTLUIOverrides";
import PixelPaperUI from "./PixelPaperUI";
import {useParams} from "react-router-dom";
import {useYjsStore} from "./useYjsStore";
import '@tldraw/tldraw/tldraw.css'
import {useCallback, useEffect, useState} from "react";
import {uploadFilesToPixelPaper} from "./Utils/FileUploads";
import {
    AssetRecordType, createShapeId,
    getHashForString,
    TLAsset,
    TLAssetId,
    TLBookmarkShape,
    TLShapePartial, Vec,
    VecLike
} from "@tldraw/editor";
import {useRoom} from "./RoomContext";

const HOST_URL = import.meta.env.VITE_PRODUCTION_URL.replace('https://', 'ws://')


function createEmptyBookmarkShape(
    editor: Editor,
    url: string,
    position: VecLike
): TLBookmarkShape {
    const partial: TLShapePartial = {
        id: createShapeId(),
        type: 'bookmark',
        x: position.x - 150,
        y: position.y - 160,
        opacity: 1,
        props: {
            assetId: null,
            url,
        },
    }

    editor.batch(() => {
        editor.createShapes([partial]).select(partial.id)
        centerSelectionAroundPoint(editor, position)
    })

    return editor.getShape(partial.id) as TLBookmarkShape
}

function centerSelectionAroundPoint(editor: Editor, position: VecLike) {
    // Re-position shapes so that the center of the group is at the provided point
    const viewportPageBounds = editor.getViewportPageBounds()
    let selectionPageBounds = editor.getSelectionPageBounds()

    if (selectionPageBounds) {
        const offset = selectionPageBounds!.center.sub(position)

        editor.updateShapes(
            editor.getSelectedShapes().map((shape) => {
                const localRotation = editor.getShapeParentTransform(shape).decompose().rotation
                const localDelta = Vec.Rot(offset, -localRotation)
                return {
                    id: shape.id,
                    type: shape.type,
                    x: shape.x! - localDelta.x,
                    y: shape.y! - localDelta.y,
                }
            })
        )
    }

    // Zoom out to fit the shapes, if necessary
    selectionPageBounds = editor.getSelectionPageBounds()
    if (selectionPageBounds && !viewportPageBounds.contains(selectionPageBounds)) {
        editor.zoomToSelection()
    }
}

export default function PixelPaper(){

    const [editor, setEditor] = useState<Editor>()

    const setAppToState = useCallback((editor: Editor) => {
        setEditor(editor)
    }, [])

    let { roomId } = useParams();
    let { accessToken } = useRoom();

    const store = useYjsStore({
        roomId: roomId,
        hostUrl: HOST_URL,
        accessToken: accessToken,
    })

    useEffect(() => {

        if (!editor) return

        const handleChangeEvent: TLEventMapHandler<'change'> = (change) => {

        }

        editor.registerExternalContentHandler('files', async ({ point, files }) => {
            await uploadFilesToPixelPaper(point, editor, files);
        });

        editor.registerExternalContentHandler('url', async ({ point, url }) => {

            const embedInfo = getEmbedInfo(url)

            if (embedInfo) {
                return editor.putExternalContent({
                    type: 'embed',
                    url: embedInfo.url,
                    point,
                    embed: embedInfo.definition,
                })
            }

            const position =
                point ??
                (editor.inputs.shiftKey ? editor.inputs.currentPagePoint : editor.getViewportPageCenter())

            const assetId: TLAssetId = AssetRecordType.createId(getHashForString(url))
            const shape = createEmptyBookmarkShape(editor, url, position)

            // Use an existing asset if we have one, or else create a new one
            let asset = editor.getAsset(assetId) as TLAsset
            let shouldAlsoCreateAsset = false
            if (!asset) {
                shouldAlsoCreateAsset = true
                try {
                    const bookmarkAsset = await editor.getAssetForExternalContent({type: 'url', url})
                    if (!bookmarkAsset) throw Error('Could not create an asset')
                    asset = bookmarkAsset
                } catch (e) {
                    return
                }
            }

            editor.batch(() => {
                if (shouldAlsoCreateAsset) {
                    editor.createAssets([asset])
                }

                editor.updateShapes([
                    {
                        id: shape.id,
                        type: shape.type,
                        props: {
                            assetId: asset.id,
                        },
                    },
                ])
            })

        });

        // [2]
        const cleanupFunction = () => {
            editor.store.listen(handleChangeEvent, { source: 'user', scope: 'all' });
        };


        return () => {
            cleanupFunction()
        }
    }, [editor])


    return (
        <div>
            <div className="tldraw__editor">
                <Tldraw
                    components={PixelPaperUIOverrides}
                    // inferDarkMode
                    onMount={setAppToState}
                    store={store}
                    autoFocus
                >

                    <PixelPaperUI />

                </Tldraw>
            </div>
        </div>
    )

}
