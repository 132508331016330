import {track, useEditor} from "@tldraw/tldraw";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useRoom} from "../RoomContext";
import {jwtDecode} from "jwt-decode";

export default function PaperTitle()
{
    return <PaperTitleComponent></PaperTitleComponent>
}

const PaperTitleComponent = track(() => {

    const editor = useEditor();

    const {accessToken} = useRoom();

    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(editor.getDocumentSettings().name === '' ? 'Untitled PixelPaper' : editor.getDocumentSettings().name);
    const [inputWidth, setInputWidth] = useState('auto');

    const titleButtonRef = useRef(null);

    useEffect(() => {
        if (titleButtonRef.current) {
            setInputWidth(`${titleButtonRef.current.offsetWidth}px`);
        }
    }, [isEditing]);


    useEffect(() => {
        if (accessToken && editor.getDocumentSettings().name === '') {
            let token = jwtDecode(accessToken);
            editor.updateDocumentSettings({name: token.name});
            setInputValue(token.name);
        }
    }, [accessToken]);

    const { roomId } = useParams();

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleTitleClick = () => {
        setIsEditing(true);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };

    const handleSave = async () => {
        try {

            await fetch(`${import.meta.env.VITE_API_URL}/api/paper/${roomId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ name: inputValue })
            });

            editor.updateDocumentSettings({name: inputValue});
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating title:', error);
        }
    };

    return (
        <div className="flex items-center all-events space-x-1">
            {isEditing ? (
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    onBlur={handleSave}
                    className="font-bold text-sme max-w-xs overflow-hidden py-2.5"
                    style={{ width: inputWidth }}
                    autoFocus
                />
            ) : (
                <button
                    className="font-bold text-sme max-w-[15rem] truncate"
                    onClick={handleTitleClick}
                    ref={titleButtonRef}
                >
                    { editor.getDocumentSettings().name === '' ? 'Untitled PixelPaper' : editor.getDocumentSettings().name }
                </button>
            )}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                 stroke="currentColor" className="w-3 h-3 cursor-pointer"
                 onClick={handleTitleClick}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5"/>
            </svg>
        </div>
    )
})
