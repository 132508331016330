import {ArrowUpRightIcon, PencilSquareIcon} from "@heroicons/react/24/outline";

export default function ArrowTool({ tool, onSelect }) {

    const componentId = 'arrow';

    const isToolSelected = tool === componentId;

    const handleClick = () => {
        if (onSelect) {
            onSelect(componentId);
        }
    };

    return (
        <button
            title={`Arrows (A)`}
            className={`default-tool-classes
                        ${isToolSelected ? 'active-tool-classes' : 'not-active-tool-classes'}`}
            onClick={handleClick}
        >
            <svg
                className={`w-8 h-8 mx-2.5 rotate-45`}
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 5l0 14"/>
                <path d="M16 9l-4 -4"/>
                <path d="M8 9l4 -4"/>
            </svg>
        </button>
    )
}
