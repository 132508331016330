import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context
const RoomContext = createContext({
    accessToken: null,
});

export const RoomProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if(!token){
            alert('Access token not provided!');
        }

        setAccessToken(token);
        setLoading(false); // Set loading to false once the token is fetched
    }, []);

    if (loading) {
        return <div>Loading...</div>; // You can customize this loading indicator
    }

    return (
        <RoomContext.Provider value={{ accessToken }}>
            {children}
        </RoomContext.Provider>
    );
};

// Create a custom hook to use the RoomContext
export const useRoom = () => {
    return useContext(RoomContext);
};
